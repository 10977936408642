import FooterStrapi from 'components/Footer'
import HeaderStrapi from 'components/Header'
import SEO, { getSEOProps } from 'components/SEO'
import { graphql } from "gatsby"
import React from "react"
import Markdown from "react-markdown"
import Moment from "react-moment"
import styled from "styled-components"
import BareLayout from "../layouts/Bare"

const Wrapper = styled.div`
  background-image: linear-gradient(90deg, #65318c, #bf339d);
  padding-bottom: 50px;
  padding: 0 20px 50px 20px;
  a {
    text-decoration: underline;
    color: #B8339C;
  }
`

const Content = styled.div`
  background-color: #fff;
  max-width: 860px;
  margin: 0 auto;
  font-size: 1.3rem;
  padding: 20px 20px 60px 20px;
`

const Header = styled.div`
  border-bottom: 1px solid #000;
  margin-bottom: 30px;
  padding-bottom: 20px;
`

const BigTitle = styled.div`
  position: relative;
  color: #fff;
  padding-top: 50px;
  box-sizing: content-box;
  box-shadow: 0 6px 0 rgba(0, 0, 0, 0.06);
  > :first-child {
    height: 65px;
    line-height: 65px;
    max-width: 860px;
    margin: 0 auto;
    font-size: 2.5rem;
  }
  margin-bottom: 50px;
`

const Title = styled.h2``

interface IProps {
  data: {
    strapiPages: Strapi.Page
  }
}

const StaticPageTemplate = ({ data }: IProps) => {
  const { strapiPages } = data
  const { title, content, lastUpdated, SEO: SEOProps } = strapiPages

  return (
    <BareLayout>
      <SEO
        {...getSEOProps(SEOProps)}
        lang='en'
        titleTemplate='%s | Night Zookeeper'
      />
      <HeaderStrapi availableLangs={[]} locale='en' />
      <Wrapper>
        <BigTitle>
          <h1>{title}</h1>
        </BigTitle>
        <Content>
          <Header>
            <Title>{title}</Title>
            <div>
              Last updated [<Moment>{lastUpdated}</Moment>]
            </div>
          </Header>
          <Markdown>{content}</Markdown>
        </Content>
      </Wrapper>
      <FooterStrapi />
    </BareLayout>
  )
}

export const query = graphql`
  query ($slug: String!) {
    strapiPages(slug: { eq: $slug }) {
      id
      title
      content
      lastUpdated
      SEO {
        title
        description
        url
        noIndex
        image {
          url
        }
        canonical
      }
    }
  }
`

export default StaticPageTemplate
